function promoSlider() {
    $('.promo__slider').slick({
        adaptiveHeight: true,
        arrows: false,
        fade: true,
        speed: 3000,
        cssEasy: 'linear',
        autoplay: true,
        autoplaySpeed: 4500,
        pauseOnFocus: false,
        pauseOnHover: false
    });
}

export default promoSlider;