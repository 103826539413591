function menu() {
    const hamburger = document.querySelector('.hamburger'),
          menu = document.querySelector('.menu'),
          menuSecond = document.querySelector('.menu-second'),
          menuPrice = document.querySelector('.menu-price'),
          closeElem = document.querySelector('.menu__close'),
          closeElemSecond = document.querySelector('.menu-second__close'),
          closeElemPrice = document.querySelector('.menu-price__close'),
          asphalting = document.querySelector('#asphalting'),
          prices = document.querySelector('#prices');

    function menuChoise(elem1, elem2, elem3) {
        elem1.addEventListener('click', () => {
            elem2.classList.add('active');
        })
    
        elem3.addEventListener('click', () => {
            elem2.classList.remove('active');
        })
    }
    
    menuChoise(hamburger, menu, closeElem);
    menuChoise(asphalting, menuSecond, closeElemSecond);
    menuChoise(prices, menuPrice, closeElemPrice);
}

export default menu;