import modal from './modules/modal';
import promoSlider from './modules/promoSlider';
import smoothScroll from './modules/smoothScroll';
import menu from './modules/menu';
import calc from './modules/calc';
import tabs from './modules/tabs';
import images from './modules/images';

window.addEventListener('DOMContentLoaded', () => {
    'use strict';
    modal();
    promoSlider();
    smoothScroll();
    menu();
    calc(); 
    tabs(); 
    images(); 
});

